/*****************************************************************************
 * Import
 *****************************************************************************/
import { useMediaQuery } from "react-responsive";

/*****************************************************************************
 * Hooks
 *****************************************************************************/
export const useMobileCheck = () => {
  return useMediaQuery({ query: `(max-width: 760px)` })
};
